<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import store from "@/store";
import categoryStoreModule from "./store/categoryStoreModule";
import axios from "@/libs/axios";
import i18n from "@/libs/i18n";
import { RequestBuilder } from "@core/utils/requestBuilder";
import vSelect from "vue-select";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import { svgs } from "@/views/components/whitelabel-templates/KB/assets/svgs";
import IconDropdown from "@/views/components/whitelabel-templates/common/IconDropDown.vue";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";

const BACK_URL = process.env.VUE_APP_URL;

export default {
  name: "index",
  components: {
    ItemForm,
    IconDropdown,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
    vSelect,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "category",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";
    const CATEGORY_KB_STORE_MODULE_NAME = "kb-category-store";
    const MODULE_KB_STORE_NAME = "kb-store";

    // Register modules
    if (!store.hasModule(MODULE_APP_STORE_NAME))
      store.registerModule(MODULE_APP_STORE_NAME, whitelabelTemplatesModule);
    if (!store.hasModule(CATEGORY_KB_STORE_MODULE_NAME))
      store.registerModule(CATEGORY_KB_STORE_MODULE_NAME, categoryStoreModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const category = ref(null);
    const lobbies = ref([]);
    const backURL = ref(BACK_URL);
    const productSelected = ref({});
    const lobbySelected = ref({});
    const products = ref([]);

    const rBuild = RequestBuilder();


    const selectOptions = ref([
      { text: i18n.t("left"), value: 1 },
      { text: i18n.t("center"), value: 2 },
      { text: i18n.t("right"), value: 3 },
    ]);

    const variants = ref([
      { value: "casino", text: "Casino" },
      { value: "sports", text: "Sports" },
      { value: "racing", text: "Racing" },
    ]);

    const icons = ref(svgs);

    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      settings
    } = UseIndex();

    settings.value.status = props.options.action
    settings.value.action = props.options.action === 'create' ? 'create' : 'edit'

    const resetCategory = () => {
      category.value = {
        name: "",
        href: "",
        sub_title: "",
        text: "",
        title: "",
        image: "",
        svgData: "",
        variant: "",
        buttonType: "link",
        position: "0",
        src: ''
      };
    };

    const resetSelected = (selectedRef) => {
      selectedRef.value = { position: 0 };
    };

    const updateCategoryFromSelection = (val, type) => {
      if (!val) {
        resetSelected(type === "lobby" ? lobbySelected : productSelected);
        return;
      }

      if (val.hasOwnProperty("position")) {
        category.value.position = val.position;
      } else {
        if (type === "lobby") {
          lobbySelected.value = val;
          category.value.id = val._id;
          category.value.title = val.name;
          category.value.href = `/lobby?id=${val._id}`;
          category.value.position = val.position || 0;
        } else {
          productSelected.value = val;
          category.value.id = val.productId;
          category.value.title = val.name;
          category.value.href = `/product/${val.productId}`;
        }
      }
      updatePreview();
    };

    const lobby = computed({
      get: () => lobbySelected.value,
      set: (val) => updateCategoryFromSelection(val, "lobby"),
    });

    const product = computed({
      get: () => productSelected.value,
      set: (val) => updateCategoryFromSelection(val, "product"),
    });

    onMounted(() => {
      resetCategory();
      const { itemsSelected } = props.options;
      if (itemsSelected) category.value = itemsSelected;
      updatePreview();
    });



    const onSubmit = async () => {
      if (settings.value.status === 'edit') {
        await updateCategory();
      } else {
        await createCategory();
      }
    };

    const sortCategories = () => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          categories: [...editTemplate.value.templateData.categories].sort(
            (a, b) => a.position - b.position
          ),
        },
      };

      store.commit(
        "app-whitelabel-templates/SET_EDIT_TEMPLATE",
        updatedTemplate
      );
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: "templateData.categories",
          }
        );
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      }
    };

    const createCategory = async () => {
      loading.value = true;
      category.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      category.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          "kb-category-store/addCategory",
          rBuild.clean(category.value)
        );

        showSuccessToast(toast, "Category", i18n.t("category_created"));
        sortCategories();
        updatePreview(response.data);
        resetAll();
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_category"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateCategory = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          "kb-category-store/updateCategory",
          {
            id: category.value._id,
            categoryData: rBuild.clean(category.value),
          }
        );

        showSuccessToast(toast, "Category", i18n.t("category_updated"));
        sortCategories();
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_update_category"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetAll = () => {
      store.commit("kb-store/SET_CLICKED_CATEGORY", null);
    };

    const updatePreview = (value) => {
      sortCategories();
      const template = editTemplate.value;
      const categoryM = value || category.value;
      const index = template.templateData.categories.findIndex(
        (obj) => obj._id === categoryM._id
      );
      if (index !== -1) {
        console.log(categoryM);
        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", {
          ...template,
          templateData: {
            ...template.templateData,
            categories: template.templateData.categories.map((category, i) =>
              i === index ? categoryM : category
            ),
          },
        });
      }
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        category.value.image = image || "";
        updatePreview();
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onIconSelected = (icon) => {
      category.value.svgData = icon.value;
      updatePreview();
    };

    const resetData = () => {
      store.commit(
        "app-whitelabel-templates/RESET_EDIT_TEMPLATE",
        "templateData.categories"
      );
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    return {
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      selectOptions,
      category,
      loading,
      variants,
      icons,
      lobbies,
      products,
      onUploadedImage,
      onIconSelected,
      lobby,
      product,
      resetCategory,
      errorInLoadImage,
      updatePreview,
      createCategory,
      updateCategory,
      settings
    };
  },
};
</script>

<template>
  <div>
    <item-form
        v-if="category"
        class="mt-1"
        @reset="resetData()"
        @save="createCategory()"
        @update="updateCategory()"
        @iconSelected="updatePreview()"
        :settings="settings"
        :item="category"
        size="651x660"
        :size-validation="true"
        :template="editTemplate"
    >
    </item-form>
<!--    <validation-observer-->
<!--      ref="refFormObserver"-->
<!--      #default="{ handleSubmit, invalid }"-->
<!--    >-->
<!--      <b-form-->
<!--        v-if="category"-->
<!--        @submit.prevent="handleSubmit(onSubmit)"-->
<!--        @reset.prevent="resetForm"-->
<!--      >-->
<!--        <b-list-group-item style="background: none" class="px-0">-->
<!--          <b-row>-->
<!--            <validation-provider-->
<!--              class="w-100"-->
<!--              #default="validationContext"-->
<!--              name="name"-->
<!--              rules="required"-->
<!--            >-->
<!--              <b-col cols="12">-->
<!--                <b-form-group label="name" label-for="c-name">-->
<!--                  <template #label>{{ $t("labels.name") }}</template>-->
<!--                  <b-form-input-->
<!--                    id="c-name"-->
<!--                    type="text"-->
<!--                    v-model="category.name"-->
<!--                    :state="getValidationState(validationContext)"-->
<!--                  />-->
<!--                  <small class="text-danger">-->
<!--                    {{ validationContext.errors[0] }}-->
<!--                  </small>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--            </validation-provider>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <b-form-group label="image" label-for="c-image">-->
<!--                <span>651x660</span>-->
<!--                <image-field-form-->
<!--                  :single-button="true"-->
<!--                  :text="$t('labels.image')"-->
<!--                  size-validation="651x660"-->
<!--                  class="w-100 mt-1"-->
<!--                  @uploadedImage="onUploadedImage"-->
<!--                  @error="errorInLoadImage"-->
<!--                  path="categories"-->
<!--                >-->
<!--                </image-field-form>-->
<!--                <img-->
<!--                  style="max-width: 100%"-->
<!--                  v-if="category.image"-->
<!--                  :src="category.image"-->
<!--                  alt="Uploaded Image"-->
<!--                  class="mt-1 rounded"-->
<!--                />-->
<!--              </b-form-group>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <b-form-group label-for="c-image">-->
<!--                <template #label>{{ $t("button_type") }}</template>-->
<!--                <v-select-->
<!--                  v-model="category.buttonType"-->
<!--                  label="text"-->
<!--                  :options="['link', 'product', 'lobby']"-->
<!--                />-->
<!--              </b-form-group>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row v-if="category.buttonType === 'lobby'">-->
<!--            <b-col cols="12">-->
<!--              <b-form-group label-for="lobby">-->
<!--                <template #label>{{ $t("lobby") }}</template>-->
<!--                <v-select-->
<!--                  rulses="required"-->
<!--                  v-model="lobby"-->
<!--                  label="name"-->
<!--                  track-by="name"-->
<!--                  :options="lobbies"-->
<!--                />-->
<!--              </b-form-group>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row v-if="category.buttonType === 'product'">-->
<!--            <b-col cols="12">-->
<!--              <b-form-group label-for="products">-->
<!--                <template #label>{{ $t("labels.products") }} </template>-->
<!--                <v-select-->
<!--                  v-model="product"-->
<!--                  required-->
<!--                  label="name"-->
<!--                  :options="products"-->
<!--                />-->
<!--              </b-form-group>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row v-if="category.buttonType === 'link'">-->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="href"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group label="Link" label-for="c-href">-->
<!--                  <template #label>{{ $t("labels.link") }}</template>-->
<!--                  <b-form-input-->
<!--                    id="c-href"-->
<!--                    type="text"-->
<!--                    v-model="category.href"-->
<!--                    :state="getValidationState(validationContext)"-->
<!--                  />-->
<!--                  <small class="text-danger">-->
<!--                    {{ validationContext.errors[0] }}-->
<!--                  </small>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <validation-provider-->
<!--              #default="validationContext"-->
<!--              name="title"-->
<!--              class="w-100"-->
<!--              rules="required"-->
<!--            >-->
<!--              <b-col cols="12">-->
<!--                <b-form-group label="title" label-for="c-title">-->
<!--                  <template #label>{{ $t("labels.title") }}</template>-->
<!--                  <b-form-input-->
<!--                    id="c-title"-->
<!--                    type="text"-->
<!--                    v-model="category.title"-->
<!--                    :state="getValidationState(validationContext)"-->
<!--                  />-->
<!--                  <small class="text-danger">-->
<!--                    {{ validationContext.errors[0] }}-->
<!--                  </small>-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--            </validation-provider>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <validation-provider #default="validationContext" name="svgData">-->
<!--                <b-form-group label-for="icon">-->
<!--                  <template #label>Icon</template>-->
<!--                  <b-input-group>-->
<!--                    <IconDropdown-->
<!--                      :icons="icons"-->
<!--                      :selected-icon-prop="category.svgData"-->
<!--                      @icon-selected="onIconSelected($event, category)"-->
<!--                    ></IconDropdown>-->
<!--                  </b-input-group>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="subtitle"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group label="subtitle" label-for="c-subtitle">-->
<!--                  <template #label>{{ $t("subtitle") }}</template>-->
<!--                  <b-form-input-->
<!--                    @input="updatePreview()"-->
<!--                    id="c-subtitle"-->
<!--                    type="text"-->
<!--                    v-model="category.sub_title"-->
<!--                    :state="getValidationState(validationContext)"-->
<!--                  />-->
<!--                  <small class="text-danger">-->
<!--                    {{ validationContext.errors[0] }}-->
<!--                  </small>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="variant"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group label="variant" label-for="c-variant">-->
<!--                  <template #label>{{ $t("labels.variant") }}</template>-->

<!--                  <b-form-select-->
<!--                    @change="updatePreview()"-->
<!--                    v-model="category.variant"-->
<!--                    :options="variants"-->
<!--                  ></b-form-select>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="text"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group label="text" label-for="cta_text">-->
<!--                  <template #label>{{ $t("labels.text") }}</template>-->

<!--                  <b-form-textarea-->
<!--                    @input="updatePreview()"-->
<!--                    rows="6"-->
<!--                    v-model="category.text"-->
<!--                  ></b-form-textarea>-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->

<!--          <b-row>-->
<!--            <b-col cols="12">-->
<!--              <validation-provider-->
<!--                #default="validationContext"-->
<!--                name="position"-->
<!--                rules="required"-->
<!--              >-->
<!--                <b-form-group label-for="position">-->
<!--                  <template #label>position</template>-->
<!--                  <b-form-input-->
<!--                    @input="updatePreview()"-->
<!--                    id="position"-->
<!--                    type="number"-->
<!--                    min="0"-->
<!--                    required-->
<!--                    v-model="category.position"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </validation-provider>-->
<!--            </b-col>-->
<!--          </b-row>-->
<!--        </b-list-group-item>-->

<!--        <div class="mt-4 d-flex flex-column" style="gap: 5px">-->
<!--          <b-button-->
<!--            :disabled="loading || invalid"-->
<!--            size="md"-->
<!--            type="submit"-->
<!--            class="w-100"-->
<!--            variant="success"-->
<!--          >-->
<!--            <b-spinner small label="Loading..." v-if="loading"></b-spinner>-->
<!--            {{-->
<!--              isEditMode-->
<!--                ? $t("buttons.update_and_apply")-->
<!--                : $t("buttons.create_and_apply")-->
<!--            }}-->
<!--          </b-button>-->
<!--          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>-->
<!--        </div>-->
<!--      </b-form>-->
<!--    </validation-observer>-->
  </div>
</template>

<style scoped lang="scss"></style>
