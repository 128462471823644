<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref } from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import vSelect from "vue-select";
import i18n from "@/libs/i18n";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import { UUIDGenerator } from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import { RequestBuilder } from "@core/utils/requestBuilder";
import footerStoreModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/store/footerStoreModule";
import SectionList from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/sectionList.vue";
import SectionForm from "@/views/components/whitelabel-templates/KB/config/PageEdit/FooterManagement/sectionForm.vue";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import { required } from "@core/utils/validations/validations";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "index",
  components: {
    SectionForm,
    SectionList,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      type: Object,
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "footer",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const FOOTER_KB_STORE_MODULE_NAME = "kb-footer-store";
    const MODULE_KB_STORE_NAME = "kb-store";
    const MODULE_APP_STORE_NAME = "app-whitelabel-templates";

    if (!store.hasModule(FOOTER_KB_STORE_MODULE_NAME))
      store.registerModule(FOOTER_KB_STORE_MODULE_NAME, footerStoreModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const footer = ref(null);
    const section = ref({
      name: "",
      items: [],
      columns: [],
      type: "",
      position: 0,
    });

    const {
      settings,
      editTemplate,
      showForm,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm
    } = UseIndex();

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetFooter();
      const { itemsSelected } = props.options;
      if (itemsSelected) footer.value = itemsSelected;
      updatePreview();
    });

    const resetFooter = () => {
      footer.value = {
        name: "",
        background_color: "",
        background_image: "",
        sections: [],
      };
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateFooter();
      } else {
        createFooter();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          `${MODULE_APP_STORE_NAME}/updateTemplate`,
          {
            template: editTemplate.value,
            toast,
            path: 'templateData.footer'
          }
        );

      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      }
    };

    const createFooter = async () => {
      loading.value = true;
      footer.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      footer.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          `${FOOTER_KB_STORE_MODULE_NAME}/addFooter`,
          rBuilder.clean(footer.value)
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_created"));
        updatePreview(response.data);
        resetAll();
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_updating_footer"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const updateFooter = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
          `${FOOTER_KB_STORE_MODULE_NAME}/updateFooter`,
          {
            id: footer.value._id,
            footerData: rBuilder.clean(footer.value),
          }
        );

        showSuccessToast(toast, "Footer", i18n.t("footer_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_creating_updating_footer"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetAll = () => {
      resetSection();
      showList();
      store.commit(`${MODULE_KB_STORE_NAME}/SET_CLICKED_FOOTER_SECTION`, null);
    };

    const resetSection = () => {
      section.value = {
        title: "",
        items: [],
        columns: [],
        type: "",
        position: 0,
      };
    };



    const showList = () => {
      settings.value.status = "list";
    };

    const onEditSection = (value) => {
      showForm();
      settings.value.action = "edit";
      section.value = value;
      updatePreview()
    };

    const onAddSection = () => {
      showForm();
      settings.value.action = "add";
      resetSection();
      updatePreview()
    };

    const onDeleteSection = (id) => {
      footer.value.sections = footer.value.sections.filter(
        (item) => item._id !== id
      );
      updatePreview();
    };

    const sortSections = () => {
      const sections = footer.value.sections;
      sections.sort((a, b) => Number(a.position) - Number(b.position));
      footer.value.sections = sections;
    };

    const saveSection = (value) => {
      section.value = {
        _id: uuidGen.generate(),
        ...value,
      };
      footer.value.sections.push(section.value);
      sortSections();
      updatePreview();
      showList();
      resetAll();
    };

    const updateSection = () => {
      footer.value.sections = footer.value.sections.map((item) =>
        item._id === section.value._id ? section.value : item
      );
      sortSections();
      updatePreview();
      showList();
      resetAll();
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        footer.value.background_image = image || "";
        updatePreview();
      }
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          footer: value || footer.value
        }
      };

      store.commit(`${MODULE_APP_STORE_NAME}/SET_EDIT_TEMPLATE`, updatedTemplate);
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.footer");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditSection,
      onAddSection,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteSection,
      loading,
      resetAll,
      footer,
      saveSection,
      updateSection,
      section,
      onUploadedImage,
      errorInLoadImage,
      updatePreview
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
      ref="refFormObserver"
      #default="{ handleSubmit, invalid }"
    >
      <b-form
        v-if="footer"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
              class="w-100"
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input id="s-name" type="text" v-model="footer.name" />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <b-row>
            <b-col cols="12">
              <!--NOT SHOW-->
              <image-field-form
                style="display: none !important"
                :single-button="true"
                :text="$t('labels.backgroundimage')"
                class="w-100 mt-1"
                @uploadedImage="onUploadedImage"
                @error="errorInLoadImage"
                path="footers"
              >
              </image-field-form>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <validation-provider
                #default="validationContext"
                name="BackgroundColor"
                rules="required"
              >
                <b-form-group
                  class="mt-1"
                  label="Background color"
                  label-for="f-backgroundColor"
                >
                  <template #label>{{ $t("labels.backgroundcolor") }}</template>
                  <b-form-input
                    @input="updatePreview()"
                    id="f-backgroundColor"
                    type="color"
                    v-model="footer.background_color"
                    :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <section-list
            :sections="footer.sections"
            v-if="settings.status === 'list'"
            @add="onAddSection()"
            @edit="onEditSection($event)"
            @delete="onDeleteSection($event)"
          >
          </section-list>
          <section-form
            class="mt-1"
            @reset="resetAll()"
            @save="saveSection(section)"
            @update="updateSection()"
            :settings="settings"
            :section="section"
            v-if="settings.status === 'form'"
          >
          </section-form>
        </b-tab>

        <div
          v-if="settings.status === 'list'"
          class="mt-4 d-flex flex-column"
          style="gap: 5px"
        >
          <b-button
            :disabled="loading || invalid"
            size="md"
            type="submit"
            class="w-100"
            variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                ? $t("buttons.update_and_apply")
                : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
