<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import { computed, onMounted, ref, watch } from "@vue/composition-api";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import formValidation from "@core/comp-functions/forms/form-validation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import store from "@/store";
import axios from "@/libs/axios";
import i18n from "@/libs/i18n";
import { RequestBuilder } from "@core/utils/requestBuilder";
import adsStoreModule from "@/views/components/whitelabel-templates/KB/config/PageEdit/AdsManagement/store/adsStoreModule";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import { axiosErrorHandle } from "@core/utils/errorHandler";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";

export default {
  name: "index",
  components: {
    ItemForm,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "ads",
        visible: false,
      }),
    },
  },

  setup(props, { emit }) {
    const MODULE_KB_STORE_NAME = "kb-store";
    const ADS_KB_STORE_MODULE_NAME = "kb-ads-store";

    // Register modules if not already registered
    if (!store.hasModule(ADS_KB_STORE_MODULE_NAME))
      store.registerModule(ADS_KB_STORE_MODULE_NAME, adsStoreModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const ad = ref({
      src: '',
      href: '',
      name: ''
    });
    const images = ref([]);
    const rBuild = RequestBuilder();

    const {
      editTemplate,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      settings
    } = UseIndex();

    settings.value.status = props.options.action
    settings.value.action = props.options.action === 'create' ? 'create' : 'edit'

    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetAd();
      if (props.options.itemsSelected) ad.value = props.options.itemsSelected;
      updatePreview();
    });

    const resetAd = () => {
      ad.value = {
        href: "",
        src: "",
        name: ''
      };
    };

    const resetData = () => {
      store.commit(
        "app-whitelabel-templates/RESET_EDIT_TEMPLATE",
        "templateData.ads"
      );
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      emit("reset");
    };

    const updatePreview = (value) => {
      const template = editTemplate.value;
      const adM = value || ad.value;
      const index = template.templateData.ads.findIndex(
        (obj) => obj._id === adM._id
      );
      if (index !== -1)
        store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", {
          ...template,
          templateData: {
            ...template.templateData,
            ads: template.templateData.ads.map((ad, i) =>
              i === index ? adM : ad
            ),
          },
        });
    };

    const onSubmit = async () => {
      if (isEditMode.value) {
        await updateAd();
      } else {
        await createAd();
      }
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
          "app-whitelabel-templates/updateTemplate",
          {
            template: editTemplate.value,
            toast,
            path: "templateData.ads",
          }
        );
      } catch (error) {
        showErrorToast(
          toast,
          i18n.t("error_updating_template"),
          axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createAd = async () => {
      loading.value = true;
      ad.value.whitelabelId =
        store.state.whitelabelCurrencyNabvar.whitelabel._id;
      ad.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
          "kb-ads-store/createAd",
          rBuild.clean(ad.value)
        );

        showSuccessToast(toast, "Ads", i18n.t("ad_created"));
        updatePreview(response.data);
        await updateTemplate();
        emit("created", response.data);
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("error_creating_update_ad"),
            text: axiosErrorHandle(error),
            icon: "EditIcon",
            variant: "error",
          },
        });
      } finally {
        loading.value = false;
      }
    };

    const updateAd = async () => {
      loading.value = true;

      try {
        const response = await store.dispatch("kb-ads-store/updateAd", {
          id: ad.value._id,
          adsData: rBuild.clean(ad.value),
        });
        showSuccessToast(toast, "Ads", i18n.t("ad_updated"));
        updatePreview(response.data);
        await updateTemplate();
        emit("updated", response.data);
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t("error_creating_update_ad"),
            text: axiosErrorHandle(error),
            icon: "EditIcon",
            variant: "error",
          },
        });
      } finally {
        loading.value = false;
      }
    };

    const errorInLoadImage = (error) => {
      toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: error,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    };

    const onUploadedImage = (data) => {
      if (data?.files.length) {
        const image = data.files[data.files.length - 1];
        ad.value.image = image || "";
        updatePreview();
      }
    };


    return {
      isEditMode,
      onSubmit,
      updateTemplate,
      resetData,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      updateAd,
      createAd,
      ad,
      images,
      settings,
      updatePreview,
      errorInLoadImage,
      loading,
      onUploadedImage,
    };
  },
};
</script>

<template>
  <div>

    <item-form
        v-if="ad"
        class="mt-1"
        @reset="resetData()"
        @save="createAd()"
        @update="updateAd()"
        @iconSelected="updatePreview()"
        :settings="settings"
        :item="ad"
        size="563x243"
        :size-validation="true"
        :template="editTemplate"
    >
    </item-form>
  </div>
</template>

<style scoped lang="scss"></style>
