<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
import AuthContent from "@/views/components/whitelabel-templates/KB/config/PageEdit/HeaderManagement/authContent.vue";
import i18n from "@/libs/i18n";
import headerStoreModule
  from "@/views/components/whitelabel-templates/KB/config/PageEdit/HeaderManagement/store/headerStoreModule";
import kbStoreModule from "@/views/components/whitelabel-templates/KB/store/kbStoreModule";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";

export default {
  name: "FEditHeader",
  components: {
    ItemForm,
    ItemList,
    AuthContent,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "header",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const HEADER_KB_STORE_MODULE_NAME = "kb-header-store";
    const MODULE_KB_STORE_NAME = "kb-store";

    if (!store.hasModule(HEADER_KB_STORE_MODULE_NAME))
      store.registerModule(HEADER_KB_STORE_MODULE_NAME, headerStoreModule);
    if (!store.hasModule(MODULE_KB_STORE_NAME))
      store.registerModule(MODULE_KB_STORE_NAME, kbStoreModule);

    const header = ref(null);
    const auth = ref(null);
    const item = ref({
      href: "",
      title: "",
      position: "",
      buttonType: "link",
      children: [],
      svgData: "",
      hoverColor: "",
    });

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      enableAddMode,
      resetForm,
      showList,
      setIndex
    } = UseIndex();

    const rBuild = RequestBuilder();


    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetHeader();
      const {itemsSelected} = props.options;
      if (itemsSelected) header.value = itemsSelected;
      initAuth();
      updatePreview();
    });

    const initAuth = () => {
      auth.value = {
        ...editTemplate.value.auth,
        background_color: "",
        color: "",
        text_color: "",
        hide: false,
      };
    };

    const resetHeader = () => {
      header.value = {
        name: "",
        links: [],
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          header: value || header.value,
          auth: auth.value
        }
      };
      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.header'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateHeader();
      } else {
        createHeader();
      }
    };

    const updateHeader = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch("kb-header-store/updateHeader", {
          id: header.value._id,
          headerData: header.value,
        });

        showSuccessToast(toast, "Header", i18n.t("header_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createHeader = async () => {
      loading.value = true;
      header.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      header.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "kb-header-store/addHeader",
            rBuild.clean(header.value)
        );

        showSuccessToast(toast, "Header", i18n.t("header_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.header");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      resetAll();
      emit("reset");
    };


    const resetItem = () => {
      item.value = {
        href: "",
        title: "",
        position: "",
        buttonType: "link",
        children: [],
        svgData: "",
        hoverColor: "",
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const onDeleteItem = (index) => {
      header.value.links.splice(index, 1)
      updatePreview();
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      store.commit("kb-store/SET_CLICKED_HEADER_LINK", null);
      // updatePreview();
    };

    const loadItemFromCache = (val) => {
        header.value.links[val.index] = val.item
    }

    const generateUUID = () => {
      return "uuidxxxxxxxxxxxxxxxxxxxx".replace(/[x]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    const sortLinks = () => {
      const links = header.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      header.value.links = links;
    };

    const saveItem = () => {
      item.value = {
        _id: generateUUID(),
        ...item.value,
      };
      header.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortLinks();
    };

    const updateItem = () => {
      header.value.links = header.value.links.map(i => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortLinks();
    };

    const editItemHandler = (val) => {
      const exist =
          header.value.links.find((i) => i._id === val._id) !== undefined;
      if (exist) {
        item.value = val;
        enableEditMode();
        showForm();
      } else {
        resetItem();
        showList();
      }
      updatePreview();
    };

    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditItem,
      auth,
      onAddItem,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteItem,
      loading,
      resetAll,
      header,
      saveItem,
      updateItem,
      item,
      editItemHandler,
      updatePreview
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="header"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="h-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="h-name"
                      type="text"
                      v-model="header.name"
                      :state="getValidationState(validationContext)"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>

          <auth-content :auth="auth"></auth-content>
        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="header.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
