<script>
import {
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BRow,
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from "vee-validate";
import ImageFieldForm from "@/views/components/ImageFieldForm.vue";
import {computed, onMounted, ref} from "@vue/composition-api";
import store from "@/store";
import whitelabelTemplatesModule from "@/views/pages/templates/store/templatesStoreModule";
import vSelect from "vue-select";
import ItemList from "@/views/components/whitelabel-templates/common/itemList.vue";
import ItemForm from "@/views/components/whitelabel-templates/common/itemForm.vue";
import i18n from "@/libs/i18n";
import sidebarStoreModule
  from "@/views/components/whitelabel-templates/KB/config/PageEdit/SidebarManagement/store/sidebarStoreModule";
import {UUIDGenerator} from "@/views/components/whitelabel-templates/common/utils/uuidGenerator";
import {RequestBuilder} from "@core/utils/requestBuilder";
import {
  showErrorToast,
  showSuccessToast,
} from "@/views/components/whitelabel-templates/common/utils/showToast";
import {axiosErrorHandle} from "@core/utils/errorHandler";
import apStoreModule from "@/views/components/whitelabel-templates/Apostala/store/apStoreModule";
import {UseIndex} from "@/views/components/whitelabel-templates/common/composables/useIndex";

export default {
  name: "FSidebarEdit",
  components: {
    ItemForm,
    ItemList,
    vSelect,
    BCardText,
    BCard,
    BFormCheckbox,
    BFormSelect,
    BButton,
    ImageFieldForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormInput,
    BFormInvalidFeedback,
    BForm,
  },

  props: {
    options: {
      default: () => ({
        action: "edit",
        itemsSelected: null,
        title: "",
        type: "sidebar",
        visible: false,
      }),
    },
  },

  setup(props, {emit}) {
    const SIDEBAR_AP_STORE_MODULE_NAME = "ap-sidebar-store";
    const MODULE_AP_STORE_NAME = "ap-store";

    if (!store.hasModule(SIDEBAR_AP_STORE_MODULE_NAME))
      store.registerModule(SIDEBAR_AP_STORE_MODULE_NAME, sidebarStoreModule);
    if (!store.hasModule(MODULE_AP_STORE_NAME))
      store.registerModule(MODULE_AP_STORE_NAME, apStoreModule);

    const sidebar = ref(null);
    const item = ref({
      href: "",
      title: "",
      buttonType: "link",
      children: [],
      svgData: "",
      hoverColor: "",
      position: 0
    });

    const uuidGen = UUIDGenerator();
    const rBuilder = RequestBuilder();

    const {
      settings,
      editTemplate,
      showForm,
      enableEditMode,
      toast,
      loading,
      refFormObserver,
      getValidationState,
      enableAddMode,
      resetForm,
      showList,
      setIndex
    } = UseIndex();

    const isEditMode = computed(() => props.options.action === "edit");

    onMounted(() => {
      resetSidebar();
      const {itemsSelected} = props.options;
      if (itemsSelected) sidebar.value = itemsSelected;
      updatePreview();
    });

    const resetSidebar = () => {
      sidebar.value = {
        name: "",
        links: [],
      };
    };

    const updatePreview = (value) => {
      const updatedTemplate = {
        ...editTemplate.value,
        templateData: {
          ...editTemplate.value.templateData,
          sidebar: value || sidebar.value
        }
      };

      store.commit("app-whitelabel-templates/SET_EDIT_TEMPLATE", updatedTemplate);
    };

    const updateTemplate = async () => {
      try {
        const template = await store.dispatch(
            "app-whitelabel-templates/updateTemplate",
            {
              template: editTemplate.value,
              toast,
              path: 'templateData.sidebar'
            }
        );

      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_updating_template"),
            axiosErrorHandle(error)
        );
      }
    };

    const onSubmit = () => {
      if (isEditMode.value) {
        updateSidebar();
      } else {
        createSidebar();
      }
    };

    const updateSidebar = async () => {
      loading.value = true;
      try {
        const response = await store.dispatch(
            "ap-sidebar-store/updateSidebar",
            {
              id: sidebar.value._id,
              sidebarData: rBuilder.clean(sidebar.value),
            }
        );

        showSuccessToast(toast, "Sidebar", i18n.t("sidebar_updated"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("updated", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const createSidebar = async () => {
      loading.value = true;
      sidebar.value.whitelabelId =
          store.state.whitelabelCurrencyNabvar.whitelabel._id;
      sidebar.value.templateId = editTemplate.value._id;

      try {
        const response = await store.dispatch(
            "ap-sidebar-store/addSidebar",
            rBuilder.clean(sidebar.value)
        );

        showSuccessToast(toast, "Sidebar", i18n.t("sidebar_created"));
        updatePreview(response.data);
        await updateTemplate();
        resetAll();
        emit("created", response.data);
      } catch (error) {
        showErrorToast(
            toast,
            i18n.t("error_creating_updating_menu"),
            axiosErrorHandle(error)
        );
      } finally {
        loading.value = false;
      }
    };

    const resetData = () => {
      store.commit("app-whitelabel-templates/RESET_EDIT_TEMPLATE", "templateData.sidebar");
      store.commit("app-whitelabel-templates/RE_RENDER_TEMPLATE");
      resetAll();
      emit("reset");
    };

    const resetItem = () => {
      item.value = {
        href: "",
        title: "",
        buttonType: "link",
        children: [],
        svgData: "",
        hoverColor: "",
        position: 0
      };
    };

    const onEditItem = (val) => {
      showForm();
      setIndex(val.index)
      enableEditMode();
      item.value = val.item;
    };

    const onAddItem = () => {
      showForm();
      enableAddMode();
      resetItem();
      updatePreview();
    };

    const onDeleteItem = (index) => {
      sidebar.value.links.splice(index, 1)
      updatePreview();
    };


    const resetAll = (val) => {
      if (val) {
        loadItemFromCache(val)
      }
      resetItem();
      showList();
      store.commit("kb-store/SET_CLICKED_SIDEBAR_LINK", null);
      // updatePreview();
    };

    const loadItemFromCache = (val) => {
      sidebar.value.links[val.index] = val.item
    }

    const sortLinks = () => {
      const links = sidebar.value.links;
      links.sort((a, b) => Number(a.position) - Number(b.position));
      sidebar.value.links = links;
    };

    const saveItem = () => {
      item.value = {
        _id: uuidGen.generate(),
        ...item.value,
      };
      sidebar.value.links.push(item.value);
      updatePreview();
      showList();
      resetAll();
      sortLinks();
    };

    const updateItem = () => {
      sidebar.value.links = sidebar.value.links.map(i => {
        if (i._id === item.value._id) {
          return item.value;
        }
        return i;
      });
      updatePreview();
      showList();
      resetAll();
      sortLinks();
    };

    const editItemHandler = (val) => {
      const exist = sidebar.value.links.find((i) => i._id === val._id) !== undefined;
      if (exist) {
        item.value = val;
        enableEditMode();
        showForm();
      } else {
        resetItem();
        showList();
      }
      updatePreview();
    };

    return {
      isEditMode,
      onSubmit,
      resetData,
      settings,
      onEditItem,
      onAddItem,
      editTemplate,
      refFormObserver,
      getValidationState,
      resetForm,
      onDeleteItem,
      loading,
      resetAll,
      sidebar,
      saveItem,
      updateItem,
      item,
      editItemHandler,
      updatePreview,
    };
  },
};
</script>

<template>
  <b-tabs content-class="mt-2" justified>
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
    >
      <b-form
          v-if="sidebar"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
      >
        <b-tab title="Content" active>
          <b-row>
            <validation-provider
                class="w-100"
                #default="validationContext"
                name="name"
                rules="required"
            >
              <b-col cols="12">
                <b-form-group label="name" label-for="s-name">
                  <template #label>{{ $t("labels.name") }}</template>
                  <b-form-input
                      id="s-name"
                      type="text"
                      v-model="sidebar.name"
                  />
                  <small class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </b-form-group>
              </b-col>
            </validation-provider>
          </b-row>
        </b-tab>

        <b-tab title="Settings">
          <item-list
              :items="sidebar.links"
              v-if="settings.status === 'list'"
              @add="onAddItem()"
              @edit="onEditItem($event)"
              @delete="onDeleteItem($event)"
          >
          </item-list>
          <item-form
              class="mt-1"
              @reset="resetAll($event)"
              @save="saveItem()"
              @update="updateItem()"
              @iconSelected="updatePreview()"
              :settings="settings"
              :item="item"
              :template="editTemplate"
              v-if="settings.status === 'form'"
          >
          </item-form>
        </b-tab>

        <div class="mt-4 d-flex flex-column" style="gap: 5px">
          <b-button
              :disabled="loading || invalid"
              size="md"
              type="submit"
              class="w-100"
              variant="success"
          >
            <b-spinner small label="Loading..." v-if="loading"></b-spinner>
            {{
              isEditMode
                  ? $t("buttons.update_and_apply")
                  : $t("buttons.create_and_apply")
            }}
          </b-button>
          <b-button @click="resetData()"> {{ $t("buttons.cancel") }}</b-button>
        </div>
      </b-form>
    </validation-observer>
  </b-tabs>
</template>

<style scoped lang="scss"></style>
